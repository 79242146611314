import React from 'react';
import { Right } from '../types/Member';

type AuthContext = {
  status: boolean;
  handleLog: (status: boolean) => void;
  setUserRights: (u: Right[]) => void;
  userRights: Right[];
  canEditMasterline: boolean;
  canEditModular: boolean;
  isAdmin: boolean;
};

const authContext = React.createContext<AuthContext>({
  status: false,
  handleLog: (status: boolean) => {},
  setUserRights: (u: Right[]) => {},
  userRights: null,
  canEditMasterline: false,
  canEditModular: false,
  isAdmin: false
});

export default authContext;
