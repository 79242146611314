import React, { FC } from 'react';
import { DxfCoordinates } from '../../../types/DxfCoordinates';
// Components
import { EditProperty, UploadDXF } from 'components';

interface Props {
  coordinates: DxfCoordinates;
  setCoordinates: (coordinates: DxfCoordinates) => void;
}

const DXF: FC<Props> = ({ coordinates, setCoordinates }) => {
  return (
    <>
      <div style={{ margin: '2rem 0' }}>
        <span>Enter Depth and X- and Y-Coordinates before uploading.</span>
        <EditProperty description="Depth:">
          <input
            className="EditProperty-Input-Textfield"
            onChange={(event) => {
              setCoordinates({
                ...coordinates,
                depth: parseFloat(event.target.value),
              });
            }}
            type="number"
            value={coordinates.depth}
          />
        </EditProperty>
        <EditProperty description="X:">
          <input
            className="EditProperty-Input-Textfield"
            onChange={(event) => {
              setCoordinates({
                ...coordinates,
                x: parseFloat(event.target.value),
              });
            }}
            step={0.0001}
            type="number"
            value={coordinates.x}
          />
        </EditProperty>
        <EditProperty description="Y:">
          <input
            className="EditProperty-Input-Textfield"
            onChange={(event) => {
              setCoordinates({
                ...coordinates,
                y: parseFloat(event.target.value),
              });
            }}
            step={0.0001}
            type="number"
            value={coordinates.y}
          />
        </EditProperty>
        <UploadDXF coordinates={coordinates} />
      </div>
    </>
  );
};

export default DXF;
