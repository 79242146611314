export default class Dimensions {
  /**
   * in CM
   */
  public static GRID = 5;

  /**
   * in CM
   */
  public static GRID_MAX = 1000;

  public static CM(mm: number) {
    return mm / 10;
  }

  public static MM(cm: number) {
    return cm * 10;
  }

  public static inGrid(n: number) {
    if (n > Dimensions.GRID_MAX) return Dimensions.GRID_MAX;
    if (n < -Dimensions.GRID_MAX) return -Dimensions.GRID_MAX;
    return n;
  }

  public static in(n: number, min: number, max: number) {
    if (n > max) return max;
    if (n < min) return min;
    return n;
  }
}
