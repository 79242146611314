import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactTable from 'react-table';

// Components
import { Button, LoadingSpinner } from 'components';

// Styles:
import './style.css';
import 'react-table/react-table.css';

// Types:
import Component from 'types/Component';

// Utils:
import { post } from 'utils/AJAX';

// =========================================================
interface Props extends RouteComponentProps {}
// =========================================================

const Components: FC<Props> = ({ history }) => {
  const [components, setComponents] = useState<Component[]>([]);
  const [loading, setLoading] = useState(false);
  const ROWS = 11;
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState(ROWS);

  // =================================

  const columns = [
    /* {
      Header: "Name",
      id: "NameCol",
      accessor: (d: any) => {
        return d.translations.vdrqR.name;
      }
    }, */
    { Header: 'Name', accessor: 'name' },
    { Header: 'ID', accessor: 'id' },
  ];

  // =================================

  const fetchComponents = async () => {
    setLoading(true);
    const { data, error, count } = await post(
      `${process.env.REACT_APP_API_URL}/component/search`,
      {
        data: {
          rows: ROWS,
          offset: offset,
          query: query,
          sort: {
            field: 'name',
            order: 'ASC',
          },
        },
      }
    );
    if (count) {
      setResults(count);
    }
    if (data) {
      /* if (offset >= 1) setComponents([...components, ...data]);
      else */ setComponents(
        data
      );
    }
    if (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchComponents();
  }, [offset]);

  useEffect(() => {
    setOffset(0);
  }, [query]);

  return (
    <div className="Components">
      <div>
        <h1>Components</h1>
      </div>
      {loading && <LoadingSpinner />}

      <div className="Components-Options">
        <div className="Components-Options-Searchform">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              fetchComponents();
            }}
          >
            <input
              className="Components-Options-Searchform-Textfield"
              onChange={(event) => {
                setQuery(event.target.value);
              }}
              placeholder="Filter Components"
              type="search"
              value={query}
            />
            {/* <Button btnType="second" type="submit">
              Apply
            </Button> */}
          </form>
        </div>

        <Button btnType="first" onClick={() => history.push('/components/new')}>
          Create Component
        </Button>
      </div>
      <div className="Components-Content">
        <ReactTable
          columns={columns}
          data={components}
          resolveData={(data) => {
            const formatted = [];
            for (let index = 0; index < offset; index++) {
              if (index < offset) {
                formatted.push({ translations: { vdrqR: { name: '' } } });
              }
            }
            formatted.push(...data);
            if (query === '') {
              for (let index = formatted.length; index < results; index++) {
                formatted.push({ translations: { vdrqR: { name: '' } } });
              }
            }
            return formatted;
          }}
          defaultPageSize={10}
          getTdProps={(
            state: any,
            rowInfo: any,
            column: any,
            instance: any
          ) => {
            return {
              onClick: (e: any, handleOriginal: any) => {
                if (rowInfo) {
                  history.push(`/components/${rowInfo.original.id}`);
                }
              },
            };
          }}
          showPageSizeOptions={false}
          onPageChange={(page) => {
            if (offset <= results) {
              setOffset(page * 10);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Components;
