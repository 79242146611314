import React, { FC, ReactNode } from 'react';

// Components:
import { Header } from 'components';

// Style:
import './style.css';

// Props:
interface Props {
  children: ReactNode;
}

const DefaultLayout: FC<Props> = ({ children }) => (
  <div className="DefaultLayout">
    <Header />
    <div className="DefaultLayout-Content">{children}</div>
  </div>
);

export default DefaultLayout;
