import React, { FC } from 'react';

// Styles:
import './style.css';

interface Props {
  description?: String;
}

export const EditProperty: FC<Props> = ({ children, description }) => {
  return (
    <div className="EditProperty">
      <div className="EditProperty-Description">
        {description && description.length > 0 && <span>{description}</span>}
      </div>
      <div className="EditProperty-Input">{children}</div>
    </div>
  );
};
