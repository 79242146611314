import React, { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactTable from 'react-table';

// Components
import { Button, Dialog, LoadingSpinner, RadioButton } from 'components';

// Styles:
import './style.css';
import 'react-table/react-table.css';

// Utils:
import { post } from 'utils/AJAX';
import authContext from '../../context/auth-context';
import { Right } from 'types/Member';
import { useSyncAllDevices } from '../../utils/useSyncAllDevices';
import { UploadExcel } from 'components/UploadExcel';

// =========================================================
interface Props extends RouteComponentProps {}

type DeviceType = 'masterline' | 'modular' | 'spaceCombi' | 'flexiChef' | 'marineMeister' | 'modularNOL' | '' | null;
// =========================================================

const Devices: FC<Props> = ({ history }) => {
  const ROWS = 11;
  const { userRights, canEditMasterline, canEditModular } = useContext(authContext);

  const getDefaultDeviceType = (rights: Right[]) => {
    if (!rights) return null;
    const masterlineRight = rights.includes('EditMasterline');
    const modularRight = rights.includes('EditModular');
    if (masterlineRight && modularRight) return '';
    if (masterlineRight) return 'masterline';
    if (modularRight) return 'modular';
    return null;
  };

  const { syncAllDevices, syncError, syncing, setShowSyncAllDialog, showSyncAllDialog } = useSyncAllDevices();

  const [devices, setDevices] = useState([]);
  const [deviceType, setDeviceType] = useState<DeviceType>(getDefaultDeviceType(userRights));
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState(ROWS);

  // =================================

  const columns = [
    {
      Header: 'Name',
      id: 'NameCol',
      accessor: (d: any) => {
        return d.translations.vdrqR.name;
      }
    },
    { Header: 'Code', accessor: 'code' }
  ];

  // =================================

  const fetchDevices = async () => {
    setLoading(true);
    const { data, error, count } = await post(`${process.env.REACT_APP_API_URL}/device/search`, {
      data: {
        rows: ROWS,
        offset: offset,
        query: query,
        sort: {
          field: 'code',
          order: 'ASC'
        },
        type: deviceType
      }
    });
    if (count) {
      setResults(count);
    }
    if (data) {
      // if (offset >= 1) setDevices([...devices, ...data]);
      // else setDevices(data);
      setDevices(data);
    }
    if (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDevices();
  }, [offset, deviceType]);

  useEffect(() => {
    setOffset(0);
  }, [query]);

  return (
    <div className="Devices">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <h1>Devices</h1>
        </div>
      )}

      <div className="Devices-Options">
        <div className="Devices-Options-Searchform">
          <form
            onSubmit={e => {
              e.preventDefault();
              fetchDevices();
            }}
          >
            <div style={{ marginBottom: '1rem' }}>
              {canEditMasterline && (
                <>
                  <RadioButton
                    checked={deviceType === 'masterline'}
                    label="MASTERLINE"
                    onChange={() => {
                      setDeviceType('masterline');
                    }}
                    value={'masterline'}
                  />
                  <RadioButton
                    checked={deviceType === 'marineMeister'}
                    label="MARINEMEISTER"
                    onChange={() => {
                      setDeviceType('marineMeister');
                    }}
                    value={'marineMeister'}
                  />
                </>
              )}
              {canEditModular && (
                <>
                  <RadioButton
                    checked={deviceType === 'modular'}
                    label="MODULAR"
                    onChange={() => {
                      setDeviceType('modular');
                    }}
                    value={'modular'}
                  />
                  <RadioButton
                    checked={deviceType === 'modularNOL'}
                    label="MODULAR NOL"
                    onChange={() => {
                      setDeviceType('modularNOL');
                    }}
                    value={'modularNOL'}
                  />
                  <RadioButton
                    checked={deviceType === 'flexiChef'}
                    label="FLEXICHEF"
                    onChange={() => {
                      setDeviceType('flexiChef');
                    }}
                    value={'flexiChef'}
                  />
                  <RadioButton
                    checked={deviceType === 'spaceCombi'}
                    label="KOMBIDÄMPFER"
                    onChange={() => {
                      setDeviceType('spaceCombi');
                    }}
                    value={'spaceCombi'}
                  />
                </>
              )}
              {Array.isArray(userRights) && userRights.includes('EditMasterline') && userRights.includes('EditModular') && (
                <RadioButton
                  checked={deviceType === ''}
                  label="ALL"
                  onChange={() => {
                    setDeviceType('');
                  }}
                  value={''}
                />
              )}
            </div>
            <input
              className="Devices-Options-Searchform-Textfield"
              onChange={event => {
                setQuery(event.target.value);
              }}
              placeholder="Search Device"
              type="search"
              value={query}
            />
            {/* <Button btnType="second" type="submit">
              Apply
            </Button> */}
          </form>
        </div>

        <Button btnType="first" onClick={() => history.push('/devices/new')}>
          Create Device
        </Button>
      </div>
      <div className="Devices-Content">
        <ReactTable
          columns={columns}
          data={devices}
          resolveData={data => {
            const formatted = [];
            for (let index = 0; index < offset; index++) {
              if (index < offset) {
                formatted.push({ translations: { vdrqR: { name: '' } } });
              }
            }
            formatted.push(...data);
            if (query === '') {
              for (let index = formatted.length; index < results; index++) {
                formatted.push({ translations: { vdrqR: { name: '' } } });
              }
            }
            return formatted;
          }}
          defaultPageSize={10}
          showPageSizeOptions={false}
          getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              onClick: (e: any, handleOriginal: any) => {
                if (rowInfo) {
                  window.open(`/devices/${rowInfo.original.id}`);
                  //history.push(`/devices/${rowInfo.original.id}`);
                }
              }
            };
          }}
          onPageChange={page => {
            if (offset <= results) {
              setOffset(page * 10);
            }
          }}
        />
      </div>
      <br />
      <br />
      <br />
      <div className="Devices-Import">
        <UploadExcel url={'/device/import/prices'}>Upload Prices list</UploadExcel>
        <UploadExcel url={'/device/import/translations'} buttonClassName="Translation">
          Upload Translation list
        </UploadExcel>
      </div>
      <br />

      <Button btnType="delete" disabled={syncing} onClick={() => setShowSyncAllDialog(true)}>
        {syncing ? <LoadingSpinner size="1rem" margin={'0.125rem auto'} /> : 'Sync all'}
      </Button>
      <Dialog
        heading="Attention!"
        open={showSyncAllDialog}
        onAbort={() => {
          setShowSyncAllDialog(false);
        }}
        onConfirm={() => {
          syncAllDevices();
        }}
        text="Are you sure you want to synchronize ALL devices? This action can not be undone."
      />
    </div>
  );
};

export default Devices;
