import * as BABYLON from 'babylonjs';
import * as React from 'react';
import BabylonScene, { SceneEventArgs, SceneMountReturn } from './BabylonScene';
import MarkUtils from './util/MarkUtils';
import LabelUtils from './util/LabelUtils';
import LoaderUtils from './util/LoaderUtils';
import DeviceNode, { PropertyType as DNPT } from './node/DeviceNode';
import BasicUtils from './util/BasicUtils';

import './PreviewBabylonScene.css';
import MaterialUtils from './util/MaterialUtils';

export type SceneProps = {
  onSceneMounted?: (args: SceneEventArgs) => SceneMountReturn;
};

export type ScreenshotMode = 'Default' | 'Combi';

export default class PreviewBabylonScene extends React.Component<SceneProps, {}> {
  public static CURRENT_SCENE: BABYLON.Scene = null;

  public setCameraFree: () => void;
  public setCameraTop: () => void;
  public setCameraFront: () => void;
  public setCameraBack: () => void;
  public setCameraDiagonal: () => void;
  public setCameraCombi: () => void;
  public toggleFeatureShadow: () => void;
  public toggleFeatureMirror: () => void;
  public toggleFeatureGrid: (value?: boolean) => void;
  public screenshot: (success: (data: string) => void, mode?: ScreenshotMode) => void;
  public setPreview: (node: BABYLON.TransformNode) => void;

  private onSceneMount = (e: SceneEventArgs) => {
    const sceneMountReturn: SceneMountReturn = {
      resizeEvents: new Array<() => void>()
    };
    const { canvas, scene, engine } = e;

    PreviewBabylonScene.CURRENT_SCENE = scene;

    //#region Scene
    // Set scene properties
    const sceneColor: number = 0.9;
    scene.clearColor = new BABYLON.Color4(sceneColor, sceneColor, sceneColor, 1);
    scene.ambientColor = new BABYLON.Color3(0.1, 0.1, 0.1);

    scene.fogMode = BABYLON.Scene.FOGMODE_LINEAR;
    scene.fogColor = new BABYLON.Color3(sceneColor, sceneColor, sceneColor);
    scene.fogStart = 1200;
    scene.fogEnd = 2200;

    // scene.animationsEnabled = false;
    // scene.collisionsEnabled = false;
    // scene.fogEnabled = true;
    // scene.lensFlaresEnabled = false;
    // scene.lightsEnabled = true;
    // scene.particlesEnabled = false;
    // scene.postProcessesEnabled = false;
    // scene.probesEnabled = false;
    // scene.texturesEnabled = true;
    // scene.proceduralTexturesEnabled = true;
    // scene.renderTargetsEnabled = true;
    scene.shadowsEnabled = true;
    // scene.skeletonsEnabled = false;
    // scene.spritesEnabled = false;

    //#region Cameras
    let cameraDistance = 275;
    const initialCameraDistance = cameraDistance;

    // Main Camera
    const camera = new BABYLON.ArcRotateCamera('Camera', 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);
    camera.setPosition(new BABYLON.Vector3(0, 150, -cameraDistance));

    camera.lowerBetaLimit = 0.01;
    camera.upperBetaLimit = (Math.PI / 2) * 0.9;
    camera.lowerRadiusLimit = 150;

    camera.cameraRotation = new BABYLON.Vector2(Math.PI, 0);

    // Top Camera for Export
    const cameraTop = new BABYLON.ArcRotateCamera('CameraTop', 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);
    //new BABYLON.FreeCamera("CameraTop", new BABYLON.Vector3(0, 800, 0), scene);
    cameraTop.setPosition(new BABYLON.Vector3(0, cameraDistance, -0.000000001));
    //cameraTop.setTarget(BABYLON.Vector3.Zero());
    cameraTop.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;

    const cameraTopResize = () => {
      const zoom = (initialCameraDistance / cameraTop.radius) * 2;
      const height = canvas.offsetHeight / zoom;
      const width = canvas.offsetWidth / zoom;
      cameraTop.orthoTop = height;
      cameraTop.orthoBottom = -height;
      cameraTop.orthoLeft = -width;
      cameraTop.orthoRight = width;
    };
    cameraTopResize();
    sceneMountReturn.resizeEvents.push(() => {
      if (scene.activeCamera === cameraTop) {
        cameraTopResize();
      }
    });

    // Front Camera for Export
    const cameraFront = new BABYLON.ArcRotateCamera('CameraFront', 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);
    cameraFront.setPosition(new BABYLON.Vector3(0, 150, -cameraDistance));

    // Back Camera for Export
    const cameraBack = new BABYLON.ArcRotateCamera('CameraBack', 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);
    cameraBack.setPosition(new BABYLON.Vector3(0, 150, cameraDistance));
    cameraBack.cameraRotation = new BABYLON.Vector2(0, 0);

    // Diagonal Camera for Export
    const cameraDiagonal = new BABYLON.ArcRotateCamera('CameraDiagonal', 0, 0, 10, new BABYLON.Vector3(0, -20, 75), scene);
    cameraDiagonal.setPosition(new BABYLON.Vector3(cameraDistance / 3, cameraDistance / 2, -cameraDistance));

    // Combi Camera for Export
    const cameraCombi = new BABYLON.ArcRotateCamera('CameraCombi', 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);
    cameraCombi.alpha = -Math.PI / 2.5;
    cameraCombi.beta = Math.PI / 2.1;
    cameraCombi.radius = cameraDistance * 1.5;
    cameraCombi.target.y = 100;

    scene.activeCamera = cameraTop;
    //#endregion

    //#region Lights
    // Main Light
    const light = new BABYLON.DirectionalLight('directional', new BABYLON.Vector3(-1, -1, 1), scene);
    light.intensity = 1;
    light.position = new BABYLON.Vector3(1000, 200, -1400);
    //light.lightmapMode = BABYLON.Light.LIGHTMAP_SHADOWSONLY;

    // Back Light
    const lightShadow = new BABYLON.DirectionalLight('directional-shadow', new BABYLON.Vector3(-1, -1.5, 1), scene);
    lightShadow.intensity = 5;
    lightShadow.position = new BABYLON.Vector3(1000, 200, -1400);
    lightShadow.lightmapMode = BABYLON.Light.LIGHTMAP_SHADOWSONLY;
    window['light'] = lightShadow;

    // Back Light
    const lightBack = new BABYLON.DirectionalLight('directional-back', new BABYLON.Vector3(1, -1, -1), scene);
    lightBack.intensity = 0.66;
    lightBack.position = new BABYLON.Vector3(-1000, 200, 1400);
    lightBack.setEnabled(false);
    //#endregion

    // Add ground
    const ground = BABYLON.Mesh.CreateGround('ground', 10000, 10000, 1, scene, false);
    ground.material = new BABYLON.StandardMaterial('ground', scene);
    (ground.material as BABYLON.StandardMaterial).specularColor = BABYLON.Color3.Black();
    (ground.material as BABYLON.StandardMaterial).diffuseColor = BABYLON.Color3.White();
    (ground.material as BABYLON.StandardMaterial).ambientColor = BABYLON.Color3.White();
    const groundGrid = new BABYLON.Texture('/texture/grid.png', scene);
    groundGrid.uScale = 100;
    groundGrid.vScale = 100;
    const groundMirrorTexture = new BABYLON.MirrorTexture('mirror', 1024, scene, true);
    groundMirrorTexture.mirrorPlane = new BABYLON.Plane(0, -1, 0, 0.1);
    groundMirrorTexture.renderList = [];
    groundMirrorTexture.level = 0.25;
    const groundMirrorTextureEmpty = new BABYLON.MirrorTexture('mirror-empty', 1024, scene, true);
    groundMirrorTextureEmpty.mirrorPlane = new BABYLON.Plane(0, -1, 0, 0.1);
    groundMirrorTextureEmpty.renderList = [];
    groundMirrorTextureEmpty.level = 0.25;
    (ground.material as BABYLON.StandardMaterial).reflectionTexture = groundMirrorTextureEmpty;
    ground.receiveShadows = true;
    ground.isPickable = false;
    // light.excludedMeshes.push(ground);
    lightShadow.excludedMeshes.push(ground);
    lightBack.excludedMeshes.push(ground);

    // Add shadows
    const shadowGenerator = new BABYLON.ShadowGenerator(1024 * 1, lightShadow, true);
    shadowGenerator.useBlurExponentialShadowMap = true;
    shadowGenerator.blurScale = 1;
    shadowGenerator.usePoissonSampling = true;
    window['shadowGenerator'] = shadowGenerator;
    // shadowGenerator.usePercentageCloserFiltering = true;

    //#region Prepare Utils
    // Prepare MaterialUtils
    MaterialUtils.initialize(scene);
    // Prepare MarkUtils
    MarkUtils.initialize(scene);
    // Prepare LabelUtils
    LabelUtils.initialize(scene);
    // Prepare LoaderUtils
    LoaderUtils.initialize(scene);
    // Prepare DeviceNode
    DeviceNode.defaultSettings.scene = scene;
    DeviceNode.defaultSettings.shadowGenerators = [shadowGenerator];
    DeviceNode.defaultSettings.mirrors = [groundMirrorTexture];
    DeviceNode.defaultSettings.modelNode = new BABYLON.TransformNode('models', scene);
    //#endregion

    //#region Chrome Fix
    // TODO Fix Fix
    const runChromeFix = () => {
      if (navigator && /.*(Chrome).*/.test(navigator.userAgent)) {
        // "Fix" first image not generated Bug
        setTimeout(() => {
          try {
            BABYLON.Tools.CreateScreenshotUsingRenderTarget(
              engine,
              cameraDiagonal,
              { width: 100, height: 100 },
              (_data: string) => {
                // Do Nothing
              },
              'image/png',
              1,
              false
            );
          } catch (e) {
            setTimeout(() => {
              runChromeFix();
            }, 100);
          }
        }, 500);
      }
    };
    //#endregion

    const renderFirstTime = () => {
      try {
        scene.render();
        // runChromeFix();
      } catch (e) {
        setTimeout(() => {
          renderFirstTime();
        }, 100);
      }
    };
    renderFirstTime();

    //#region RenderLoop
    engine.runRenderLoop(() => {
      try {
        if (scene && document.hasFocus()) {
          scene.render();
        }
      } catch (e) {
        console.error(e);
      }
    });
    //#endregion

    //#region Debug Views
    const beforeCameraChange = () => {
      if (scene.activeCamera === camera)
        setTimeout(() => {
          camera.detachControl(canvas);
        }, 0);
    };
    const afterCameraChange = () => {
      if (scene.activeCamera === camera)
        setTimeout(() => {
          camera.attachControl(canvas, true);
        }, 0);

      if (scene.activeCamera === cameraTop) this.toggleFeatureGrid(true);
      else this.toggleFeatureGrid(false);

      if (scene.activeCamera === cameraDiagonal || scene.activeCamera === cameraCombi) {
        scene.clearColor.a = 0.00000000000000000001;
        ground.setEnabled(false);
      } else {
        scene.clearColor.a = 1;
        ground.setEnabled(true);
      }
    };
    this.setCameraFree = () => {
      beforeCameraChange();
      scene.activeCamera = camera;
      afterCameraChange();
    };
    this.setCameraTop = () => {
      beforeCameraChange();
      scene.activeCamera = cameraTop;
      cameraTopResize();
      afterCameraChange();
    };
    this.setCameraFront = () => {
      beforeCameraChange();
      scene.activeCamera = cameraFront;
      afterCameraChange();
    };
    this.setCameraBack = () => {
      beforeCameraChange();
      scene.activeCamera = cameraBack;
      afterCameraChange();
    };
    this.setCameraDiagonal = () => {
      beforeCameraChange();
      scene.activeCamera = cameraDiagonal;
      afterCameraChange();
    };
    this.setCameraCombi = () => {
      beforeCameraChange();
      scene.activeCamera = cameraCombi;
      afterCameraChange();
    };
    this.toggleFeatureShadow = () => {
      scene.shadowsEnabled = !scene.shadowsEnabled;
    };
    this.toggleFeatureMirror = () => {
      const groundMaterial = ground.material as BABYLON.StandardMaterial;
      if (groundMaterial.reflectionTexture === groundMirrorTexture) {
        groundMaterial.reflectionTexture = groundMirrorTextureEmpty;
      } else {
        groundMaterial.reflectionTexture = groundMirrorTexture;
      }
    };
    this.toggleFeatureGrid = (value?: boolean) => {
      if (typeof value !== 'undefined') {
        if (!value) {
          (ground.material as BABYLON.StandardMaterial).diffuseTexture = undefined;
        } else {
          (ground.material as BABYLON.StandardMaterial).diffuseTexture = groundGrid;
        }
      } else {
        if ((ground.material as BABYLON.StandardMaterial).diffuseTexture) {
          (ground.material as BABYLON.StandardMaterial).diffuseTexture = undefined;
        } else {
          (ground.material as BABYLON.StandardMaterial).diffuseTexture = groundGrid;
        }
      }
    };
    this.screenshot = (success: (data: string) => void, mode: ScreenshotMode = 'Default') => {
      switch (mode) {
        case 'Default':
          BABYLON.Tools.CreateScreenshotUsingRenderTarget(
            engine,
            cameraDiagonal,
            { width: 320, height: 320 },
            (data: string) => {
              success(data);
            },
            'image/png',
            1,
            true
          );
          break;
        case 'Combi':
          BABYLON.Tools.CreateScreenshotUsingRenderTarget(
            engine,
            cameraCombi,
            { width: 320, height: 320 },
            (data: string) => {
              success(data);
            },
            'image/png',
            1,
            true
          );
          break;
      }
    };
    this.setPreview = (node: BABYLON.TransformNode) => {
      cameraDiagonal.target.x = node.position.x;
    };
    const debugEvents = (e: KeyboardEvent) => {
      switch (e.key) {
        case '+':
          scene.debugLayer.show();
          break;
      }
    };
    canvas.addEventListener('keyup', debugEvents);
    //#endregion

    scene.onDispose = () => {
      canvas.removeEventListener('keyup', debugEvents);
    };

    // Add HDR Texture
    const hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData('/texture/hdr/environment.env', scene);
    hdrTexture.gammaSpace = false;
    hdrTexture.level = 0.75;
    scene.environmentTexture = hdrTexture;
    window['hdr'] = hdrTexture;

    if (typeof this.props.onSceneMounted === 'function') {
      const rv = this.props.onSceneMounted(e);
      for (let i = 0; i < rv.resizeEvents.length; i++) {
        const re = rv.resizeEvents[i];
        sceneMountReturn.resizeEvents.push(re);
      }
    }

    this.setCameraFree();

    return sceneMountReturn;
  };

  render() {
    return (
      <div className="Preview-Scene">
        <h2>Preview Scene</h2>
        <div className="Preview-Scene-Menu">
          <h3>Camera</h3>
          <button
            onClick={() => {
              this.setCameraFree();
            }}
          >
            Free
          </button>
          <button
            onClick={() => {
              this.setCameraTop();
            }}
          >
            Top
          </button>
          <button
            onClick={() => {
              this.setCameraFront();
            }}
          >
            Front
          </button>
          <button
            onClick={() => {
              this.setCameraBack();
            }}
          >
            Back
          </button>
          <button
            onClick={() => {
              this.setCameraDiagonal();
            }}
          >
            Diagonal
          </button>
          <button
            onClick={() => {
              this.setCameraCombi();
            }}
          >
            Combi
          </button>
          <h3>Features</h3>
          <button
            onClick={() => {
              this.toggleFeatureShadow();
            }}
          >
            Shadow
          </button>
          <button
            onClick={() => {
              this.toggleFeatureMirror();
            }}
          >
            Mirror
          </button>
          <button
            onClick={() => {
              this.toggleFeatureGrid();
            }}
          >
            Grid
          </button>
          {this.props.children}
        </div>
        <div className="Preview-Scene-Canvas">
          <BabylonScene
            onSceneMount={this.onSceneMount}
            engineOptions={{ preserveDrawingBuffer: true, stencil: true }}
            sceneOptions={{ useClonedMeshMap: true }}
          />
        </div>
      </div>
    );
  }
}
