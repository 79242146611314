import { post } from "./AJAX";

const getComponents = async (
  query = "",
  rows = 10,
  sort = { field: "name", order: "ASC" },
  offset = 0
) => {
  const { data, error } = await post(
    `${process.env.REACT_APP_API_URL}/component/search`,
    {
      data: {
        rows,
        offset,
        query,
        sort
      }
    }
  );
  return { data, error };
};

export default getComponents;
