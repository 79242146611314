import React, { FC } from 'react'

// Components
import { EditProperty } from 'components'

// Types:
import { Energy, EnergySource, Model } from 'types/Device'

interface Props {
  energy: Energy
  setEnergy: (nrg: Energy) => void
  model: Model
  setModel: (nM: Model) => void
}

const DeviceModel: FC<Props> = ({ energy, setEnergy, model, setModel }) => {
  return (
    <>
      <h3 className='Device-SubCategory-Header'>Model</h3>
      <div className='Device-SubCategory'>
        <EditProperty description='Width:'>
          <input
            className='EditProperty-Input-Textfield'
            onChange={(event) => {
              setModel({
                ...model,
                width: parseFloat(event.target.value),
              })
            }}
            placeholder='0'
            type='number'
            value={model.width}
          />
        </EditProperty>
        <EditProperty description='Width Substructure:'>
          <input
            className='EditProperty-Input-Textfield'
            onChange={(event) => {
              setModel({
                ...model,
                widthSubstructure: parseFloat(event.target.value),
              })
            }}
            placeholder='0'
            type='number'
            value={model.widthSubstructure}
          />
        </EditProperty>
        <EditProperty description='Height:'>
          <input
            className='EditProperty-Input-Textfield'
            onChange={(event) => {
              setModel({
                ...model,
                height: parseFloat(event.target.value),
              })
            }}
            placeholder='0'
            type='number'
            value={model.height}
          />
        </EditProperty>
        {model.depths.map((depth, index) => (
          <EditProperty key={`property-${index}-depth`} description={index === 0 ? 'Depths:' : ''}>
            <div style={{ display: 'flex' }}>
              <input
                className='EditProperty-Input-Textfield'
                onChange={(event) => {
                  const newDepths = [...model.depths]
                  newDepths[index] = parseFloat(event.target.value)
                  setModel({
                    ...model,
                    depths: newDepths,
                  })
                }}
                placeholder='0'
                type='number'
                value={depth}
              />
              <button
                onClick={() => {
                  const newDepths = [...model.depths]
                  newDepths.splice(index, 1)
                  setModel({ ...model, depths: newDepths })
                }}
              >
                X
              </button>
            </div>
          </EditProperty>
        ))}
        <button
          onClick={() => {
            setModel({ ...model, depths: [...model.depths, 0] })
          }}
          style={{ marginTop: '1rem' }}
        >
          Add Depth
        </button>
      </div>
      <h3 className='Device-SubCategory-Header'>Energy</h3>

      <div className='Device-SubCategory'>
        <EditProperty description='Source:'>
          <form action=''>
            <select
              onChange={(event) => {
                const energies = [
                  EnergySource[0],
                  EnergySource[1],
                  EnergySource[2],
                  EnergySource[3],
                ]
                setEnergy({
                  ...energy,
                  source: energies[event.target.value],
                })
              }}
              id='Device-Source-Selectbox'
              value={EnergySource[energy.source]}
            >
              <option value={EnergySource.Neutral}>Neutral</option>
              <option value={EnergySource.Electro}>Electro</option>
              <option value={EnergySource.Gas}>Gas</option>
              <option value={EnergySource.Steam}>Steam</option>
            </select>
          </form>
        </EditProperty>
        <EditProperty description='Elektro:'>
          <input
            className='EditProperty-Input-Textfield'
            onChange={(event) => {
              setEnergy({
                ...energy,
                elektro: parseFloat(event.target.value),
              })
            }}
            placeholder='0'
            type='number'
            value={energy.elektro}
          />
        </EditProperty>
        <EditProperty description='Gas:'>
          <input
            className='EditProperty-Input-Textfield'
            onChange={(event) => {
              setEnergy({
                ...energy,
                gas: parseFloat(event.target.value),
              })
            }}
            placeholder='0'
            type='number'
            value={energy.gas}
          />
        </EditProperty>
        <EditProperty description='Steam:'>
          <input
            className='EditProperty-Input-Textfield'
            onChange={(event) => {
              setEnergy({
                ...energy,
                steam: parseFloat(event.target.value),
              })
            }}
            placeholder='0'
            type='number'
            value={energy.steam}
          />
        </EditProperty>

        <EditProperty description='Voltage'>
          <input
            className='EditProperty-Input-Textfield'
            onChange={(event) => {
              setEnergy({
                ...energy,
                voltage: parseFloat(event.target.value),
              })
            }}
            placeholder='0'
            type='number'
            value={energy.voltage}
          />
        </EditProperty>
        <EditProperty description='Electro Conditions:'>
          <input
            className='EditProperty-Input-Textfield'
            onChange={(event) => {
              setEnergy({
                ...energy,
                electroConditions: event.target.value,
              })
            }}
            placeholder='Electro Conditions'
            value={energy.electroConditions}
          />
        </EditProperty>
      </div>
    </>
  )
}

export default DeviceModel
