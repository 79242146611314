import { Subtype } from 'types/DeviceEnum';

export const isSubtype = (s: Subtype | string, comp: Array<Subtype | string>) => {
  s = toSubtype(s);
  for (let i = 0; i < comp.length; i++) {
    let c = comp[i];
    c = toSubtype(c);
    if (s === c) return true;
  }
  return false;
};

export const toSubtype = (s: Subtype | string) => {
  // @ts-ignore
  if (typeof s === 'string') s = Subtype[s];
  return s;
};
