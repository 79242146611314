import React, { FC } from 'react';

// Style:
import './style.css';

interface Props {
  color?: 'white' | 'blue' | 'medium';
  size?: string;
  borderWidth?: number;
  margin?: string;
}

// ===================================================================
export const LoadingSpinner: FC<Props> = ({
  size = '40px',
  borderWidth = 3,
  margin = '2rem auto',
}) => (
  <div
    className="LoadingSpinner"
    style={{
      height: size,
      width: size,
      borderWidth: borderWidth,
      margin,
    }}
  />
);
