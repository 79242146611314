import React, { FC } from 'react';

import './style.scss';

interface Props extends React.HTMLProps<HTMLButtonElement> {
  btnType: 'first' | 'second' | 'third' | 'fourth' | 'delete';
}

export const Button: FC<Props> = ({
  children,
  onClick,
  btnType,
  style,
  disabled = false,
}) => {
  switch (btnType) {
    case 'first':
      return (
        <button
          className="Button-First"
          onClick={onClick}
          style={style}
          disabled={disabled}
        >
          {children}
        </button>
      );
    case 'second':
      return (
        <button
          className="Button-Second"
          onClick={onClick}
          style={style}
          disabled={disabled}
        >
          {children}
        </button>
      );
    case 'third':
      return (
        <button
          className="Button-Third"
          onClick={onClick}
          style={style}
          disabled={disabled}
        >
          {children}
        </button>
      );
    case 'fourth':
      return (
        <button
          className="Button-Fourth"
          onClick={onClick}
          style={style}
          disabled={disabled}
        >
          {children}
        </button>
      );
    case 'delete':
      return (
        <button
          className="Button-Delete"
          onClick={onClick}
          style={style}
          disabled={disabled}
        >
          {children}
        </button>
      );
    default:
      return (
        <button onClick={onClick} style={style} disabled={disabled}>
          {children}
        </button>
      );
  }
};
