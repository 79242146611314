import React, { FC, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

// Components
import { ButtonHollow } from 'components';

interface Props {
  error: boolean;
  id: string;
  setUploadedImage: (img: File) => void;
  success: boolean;
  uploadedImage?: File;
}

export const UploadImage: FC<Props> = ({
  error,
  id,
  setUploadedImage,
  success,
  uploadedImage,
}) => {
  const [image, setImage] = useState(
    id && id !== 'new'
      ? `${process.env.REACT_APP_API_URL}/device/get/${id}/model/image`
      : null
  );

  const onDrop = useCallback(
    (files: File[]) => {
      if (files[0].type === 'image/png') {
        setImage(URL.createObjectURL(files[0]));
        setUploadedImage(files[0]);
      }
    },
    [setUploadedImage]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  console.log(image);

  if (id === 'new') {
    return <div>Please save device before uploading an image.</div>;
  }

  return (
    <div>
      <span>Device Image</span>
      <div>
        <img
          className="Device-Image"
          src={image}
          alt={`Click below to upload`}
        />
      </div>
      {success && <span>Image Changed.</span>}
      {error && <span>Could not change Image.</span>}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <ButtonHollow onClick={() => {}}>Upload Image</ButtonHollow>
      </div>
    </div>
  );
};
