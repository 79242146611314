import { post } from './AJAX'

const getUsers = async (
  query = '',
  rows = 10,
  sort = { field: 'name', order: 'ASC' },
  offset = 0
) => {
  const { data, error, count } = await post(`${process.env.REACT_APP_API_URL}/member/search`, {
    data: {
      rows,
      offset,
      query: query,
      sort: {
        field: 'email',
        order: 'ASC',
      },
    },
  })
  return { data, error }
}

export default getUsers
