import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

// Assets:
import Logo from 'assets/images/logo@2x.png'

// Context
import AuthContext from 'context/auth-context'

// Styles:
import './style.css'

// Utils:
import { post } from 'utils/AJAX'

export const Header = () => {
  const { handleLog } = useContext(AuthContext)

  const logout = async () => {
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/auth/logout`)
    window.localStorage.removeItem('jwt-mkn-admin')
    window.localStorage.removeItem('mkn-userRights')
    if (error) {
      console.log(error)
    }
    handleLog(false)
  }

  return (
    <div className='Header'>
      <div className='Header-Item'>
        <img className='Header-Logo' src={Logo} alt='MKN Logo' />
      </div>
      <div className='Header-Item'>
        <NavLink
          activeClassName='Header-Navlink-Active'
          className='Header-Navlink'
          to='/categories'
        >
          Categories
        </NavLink>
        <NavLink
          activeClassName='Header-Navlink-Active'
          className='Header-Navlink'
          to='/components'
        >
          Components
        </NavLink>
        <NavLink activeClassName='Header-Navlink-Active' className='Header-Navlink' to='/devices'>
          Devices
        </NavLink>
        <NavLink activeClassName='Header-Navlink-Active' className='Header-Navlink' to='/users'>
          Users
        </NavLink>
        <NavLink
          activeClassName='Header-Navlink-Active'
          className='Header-Navlink'
          to='/organizations'
        >
          Organizations
        </NavLink>
        <NavLink activeClassName='Header-Navlink-Active' className='Header-Navlink' to='/languages'>
          Languages
        </NavLink>
        <NavLink activeClassName='Header-Navlink-Active' className='Header-Navlink' to='/miscs'>
          Miscs
        </NavLink>
        <button className='Header-Logout' onClick={logout}>
          Logout
        </button>
      </div>
    </div>
  )
}
