import { post } from "utils/AJAX";

const getLanguages = async () => {
  const { data, error } = await post(
    `${process.env.REACT_APP_API_URL}/language/search`,
    {
      data: {
        rows: 999,
        offset: 0,
        query: "",
        sort: {
          field: "name",
          order: "ASC"
        }
      }
    }
  );
  return { data, error };
};

export default getLanguages;
