import * as BABYLON from 'babylonjs';
import { Mesh } from 'babylonjs';
import { DeviceComponent } from 'types/Device';
import { Subtype } from 'types/DeviceEnum';
import Dimensions from 'utils/Dimensions';
import BasicUtils from '../util/BasicUtils';
import DeviceNode, { PropertyType } from './DeviceNode';

export class OptionNode extends BABYLON.TransformNode {
  private _container: DeviceNode;
  private _deviceComponent: DeviceComponent;
  private _model: BABYLON.TransformNode;

  constructor(node: BABYLON.TransformNode, deviceComponent: DeviceComponent) {
    super('option.' + deviceComponent.component.id, node.getScene(), undefined);
    this._model = node;
    this._model.parent = this;
    this._deviceComponent = deviceComponent;
  }

  public prepareType() {
    if (this._deviceComponent && this._container) {
      let targetWidth = this._container.get(PropertyType.Width) as number;

      // mm to cm
      const width = targetWidth / 10;
      // cm to scaling
      const scaling = width / 10;

      switch (this._deviceComponent.component.id) {
        // WingedDoor
        case '7KP0K':
          {
            // To fix a wired bug we put the model under a fresh node....
            const front = new BABYLON.TransformNode('Front', this.getScene());
            front.parent = this;
            BasicUtils.findFirstChild('door', this._model).parent = front;
            this._model.dispose();
            this._model = front;

            if ('Door2' == this._container.getDeviceObject().style) {
              const halfWidth = width / 2;
              const halfScaling = scaling / 2;
              const doorLeft = BasicUtils.findFirstChild('door', this._model);
              const doorRight = BasicUtils.clone(doorLeft, 'DoorRight');
              // Left
              {
                BasicUtils.findFirstChild('Center', doorLeft).scaling.x = Math.max(0, halfScaling - 2);
                const right = BasicUtils.findFirstChild('Right', doorLeft);
                right.position.x = halfWidth - 20;
              }
              // Right
              {
                BasicUtils.findFirstChild('Center', doorRight).scaling.x = Math.max(0, halfScaling - 2);
                const right = BasicUtils.findFirstChild('Right', doorRight);
                right.position.x = halfWidth - 20;
                doorRight.scaling.x = -1;
                doorRight.position.x = width;
              }
            } else {
              BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
              const right = BasicUtils.findFirstChild('Right', this._model);
              right.position.x = width - 20;
            }

            const back = BasicUtils.clone(this._model, 'Back');
            back.scaling.z = -1;
            back.getChildTransformNodes().forEach(child => {
              if (child.name === 'Flex') {
                child.name = 'NoFlex';
                child.position.z = 0;
              }
            });
          }
          break;
        // UpperDrawer
        case 'd2nB2':
        // Zwischen boden
        case 'Kov1L':
        case 'L38zK':
          {
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
            const left = BasicUtils.findFirstChild('Left', this._model);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
          }
          break;
        // Drawer 1x & 2x
        case 'WKOJL':
        case 'R2QoL':
          {
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
          }
          break;
        // Hygiene
        case 'OK7l2':
          {
            //const node = BasicUtils.findFirstChild('Hygiene', this._model);
            this._model.name = 'HygieneClosed';
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling);
            const left = BasicUtils.findFirstChild('Left', this._model);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;

            const open = BasicUtils.clone(this._model, 'HygieneOpen');
            BasicUtils.findFirstChild('Center', open).dispose();
            BasicUtils.findFirstChild('Left:Shared', open).dispose();
            BasicUtils.findFirstChild('Left:Extension', open).dispose();
            BasicUtils.findFirstChild('Right:Shared', open).dispose();
            BasicUtils.findFirstChild('Right:Extension', open).dispose();
            const openLeftFlex = BasicUtils.clone(BasicUtils.findFirstChild('Left:Flex', open), 'Shared');
            openLeftFlex.scaling.z = -1.5;
            openLeftFlex.position.z = -120;
            const openRightFlex = BasicUtils.clone(BasicUtils.findFirstChild('Right:Flex', open), 'Shared');
            openRightFlex.scaling.z = openLeftFlex.scaling.z;
            openRightFlex.position.z = openLeftFlex.position.z;
          }
          break;
        // AngularRail
        case 'wKBAL':
          {
            const node = BasicUtils.findFirstChild('URail', this._model);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
          }
          break;
        // UpturnBack
        case '28OwK':
          {
            const left = BasicUtils.findFirstChild('Left', this._model);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
          }
          break;
      }
    }

    this.prepareTypeWidthChange();
    this.prepareTypeDepthChange();
    this.prepareOptionChange();
  }

  public prepareTypeWidthChange() {
    if (this._deviceComponent && this._container) {
      let targetWidth = this._container.get(PropertyType.Width) as number;

      // mm to cm
      const width = targetWidth / 10;
      // cm to scaling
      const scaling = width / 10;

      switch (this._deviceComponent.component.id) {
        // UpperDrawer
        case 'd2nB2':
        // Zwischen boden
        case 'Kov1L':
        case 'L38zK':
          {
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
            const right = (BasicUtils.findFirstChild('Right', this._model).position.x = width);
          }
          break;
        // Drawer 1x & 2x
        case 'WKOJL':
        case 'R2QoL':
          {
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
            BasicUtils.findFirstChild('Right', this._model).position.x = width - 20;
          }
          break;
        // Hygiene
        case 'OK7l2':
          {
            //const node = BasicUtils.findFirstChild('Hygiene', this._model);
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling);
            BasicUtils.findFirstChild('Right', this._model).position.x = width;

            const open = BasicUtils.findFirstChild('HygieneOpen', this);
            BasicUtils.findFirstChild('Right', open).position.x = width;
          }
          break;
        // AngularRail
        case 'wKBAL':
          {
            const node = BasicUtils.findFirstChild('URail', this._model);
            BasicUtils.findFirstChild('Right', node).position.x = width;
          }
          break;
        // MixingFaucet
        case 'g28wK':
        // Kaltwasserzulauf
        case '2ZyYK':
          {
            this._model.position.x = width / 2;
          }
          break;
        // EOven
        case 'GLzd2':
        // GOven
        case 'eKRML':
        // GOvenExhaust
        case '32bxL':
          if (targetWidth > 800) {
            this._model.position.x = width - 80;
          }
          break;
        // Emptying System
        case 'E4mx2':
          const esw = 26.848;
          this._model.scaling.x = (width - 5) / esw;
          break;
        // UpturnBack
        case '28OwK':
          {
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
            BasicUtils.findFirstChild('Right', this._model).position.x = Math.max(0, width - 20);
          }
          break;
        // Flexi
        case 'g4XmL':
        case 'k2VxL':
        case 'dKpgL':
          if (this.getDeviceComponent().scaling.x == -1) {
            const mesh = BasicUtils.findFirstChild('display', this) as Mesh;
            mesh.material = this.getScene().getMaterialByName(mesh.material.name + '_m');
            const klappe = BasicUtils.findFirstChild('sc_logo:klappe', this) as Mesh;
            if (klappe) klappe.material = this.getScene().getMaterialByName(klappe.material.name + '_m');
          }
          break;
        // GN Gestell
        case 'LzndL':
          const gnw = 45;
          if (width < 70) {
            this._model.position.x = width / 2 - gnw / 2;
          }
          break;
      }
    }
  }

  public prepareTypeDepthChange() {
    if (this._deviceComponent && this._container) {
      const depth = this._container.get(PropertyType.Depth) as number;
      switch (this._deviceComponent.component.id) {
        // WingedDoor
        case '7KP0K':
          {
            const back = BasicUtils.findFirstChild('Back', this);
            back.position.z = depth == 700 ? -95 : -80;
            BasicUtils.computeAllWorldMatrix(back);
          }
          break;
        // Hygiene
        case 'OK7l2':
        // AngularRail
        case 'wKBAL':
          {
            if (depth === 700) {
              this.getChildTransformNodes(false, n => n.name === 'Left').forEach(n =>
                n.getChildTransformNodes(false, n => n.name === 'Flex').forEach(n => (n.position.z = 15))
              );
              this.getChildTransformNodes(false, n => n.name === 'Right').forEach(n =>
                n.getChildTransformNodes(false, n => n.name === 'Flex').forEach(n => (n.position.z = 15))
              );
            } else {
              this.getChildTransformNodes(false, n => n.name === 'Left').forEach(n =>
                n.getChildTransformNodes(false, n => n.name === 'Flex').forEach(n => (n.position.z = 0))
              );
              this.getChildTransformNodes(false, n => n.name === 'Right').forEach(n =>
                n.getChildTransformNodes(false, n => n.name === 'Flex').forEach(n => (n.position.z = 0))
              );
            }
          }
          break;
        // GN Gestell
        case 'LzndL':
          this._model.getChildTransformNodes(true).forEach(n => (n.position.z = -17));
          break;
        // Warmhalteschublade
        case 'KRRMK':
        // Warmhaltegerät
        case '2baxK':
          this._model.getChildTransformNodes(true).forEach(n => (n.position.z = -15));
          break;
        // Zwischen boden
        case 'Kov1L':
        case 'L38zK':
          {
            if (depth === 700) {
              this._model.getChildTransformNodes().forEach(n => (n.name === 'Shared' ? (n.position.z = -15) : ''));
            } else {
              this._model.getChildTransformNodes().forEach(n => (n.name === 'Shared' ? (n.position.z = 0) : ''));
            }
          }
          break;
      }
    }
    BasicUtils.computeAllWorldMatrix(this);
  }

  public prepareOptionChange() {
    if (this._deviceComponent && this._container) {
      let targetWidth = this._container.get(PropertyType.Width) as number;

      // mm to cm
      const width = targetWidth / 10;
      // cm to scaling
      const scaling = width / 10;
    }
    this.prepareTypeDepthChange();
    // BasicUtils.computeAllWorldMatrix(this);
  }

  public getDeviceComponent() {
    return this._deviceComponent;
  }

  public getModel() {
    return this._model;
  }

  public setContainer(container: DeviceNode) {
    this._container = container;
  }
}
