import React, { useEffect, useState } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

// Context:
import AuthContext from 'context/auth-context';

// Components:
import { LoadingSpinner } from 'components';

// Layout:
import DefaultLayout from 'layout/Default';

// Pages:
import Categories from 'page/Categories';
import CategoryEdit from 'page/CategoryEdit';
import Components from 'page/Components';
import ComponentEdit from 'page/ComponentEdit';
import Devices from 'page/Devices';
import DeviceEdit from 'page/DeviceEdit';
import Languages from 'page/Languages';
import LanguageEdit from 'page/LanguageEdit';
import Login from 'page/Login';
import OrganizationEdit from 'page/OrganizationEdit';
import Organizations from 'page/Organizations';
import Users from 'page/Users';
import UserEdit from 'page/UserEdit';

// Styles:
import './App.css';

// Utils:
import { get } from 'utils/AJAX';
import { Right } from '../../types/Member';
import MiscEdit from 'page/MiscEdit';

const App: React.FC = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userRights, setUserRights] = useState<Right[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchStatus = async () => {
    const { data, error } = await get(`${process.env.REACT_APP_API_URL}/admin/auth/status`, {});

    if (error) {
      setAuthenticated(false);
    }
    if (data) {
      if (data.key) {
        window.localStorage.setItem('jwt-mkn-admin', data.key);
      }
      setAuthenticated(data.loggedIn);
    }
    setLoading(false);
  };

  if (!window['statusInterval']) {
    // Check status every 15 min
    window['statusInterval'] = setInterval(async () => {
      const { data, error } = await get(`${process.env.REACT_APP_API_URL}/admin/auth/status`, {});

      if (error) {
        setAuthenticated(false);
      }
      if (data) {
        if (data.key) {
          window.localStorage.setItem('jwt-mkn-admin', data.key);
        }
        setAuthenticated(data.loggedIn);
      }
    }, 900000);
  }

  const handleLogin = (status: boolean) => {
    setAuthenticated(status);
  };

  useEffect(() => {
    fetchStatus();
    const rights = JSON.parse(window.localStorage.getItem('mkn-userRights'));
    setUserRights(rights);
  }, [authenticated]);

  const isAdmin = userRights?.includes('Admin');

  const routes = authenticated ? (
    <DefaultLayout>
      <Switch>
        <Route exact path="/categories" component={Categories} />
        <Route path="/categories/:id" component={CategoryEdit} />
        <Route exact path="/components" component={Components} />
        <Route path="/components/:id" component={ComponentEdit} />
        <Route exact path="/devices" component={Devices} />
        <Route path="/devices/:id" component={DeviceEdit} />
        <Route exact path="/languages" component={Languages} />
        <Route path="/languages/:id" component={LanguageEdit} />
        <Route exact path="/users" component={Users} />
        <Route path="/organizations/:id" component={OrganizationEdit} />
        <Route exact path="/organizations" component={Organizations} />
        <Route exact path="/miscs" component={MiscEdit} />
        <Route path="/users/:id" component={UserEdit} />
        <Redirect to="/devices" />
      </Switch>
    </DefaultLayout>
  ) : (
    <Switch>
      <Route exact path="/" component={Login} />
      <Redirect to="/" />
    </Switch>
  );

  return loading ? (
    <LoadingSpinner />
  ) : (
    <AuthContext.Provider
      value={{
        status: authenticated,
        handleLog: handleLogin,
        userRights,
        setUserRights,
        isAdmin,
        canEditMasterline: isAdmin || userRights?.includes('EditMasterline'),
        canEditModular: isAdmin || userRights?.includes('EditModular')
      }}
    >
      {routes}
    </AuthContext.Provider>
  );
};

export default withRouter(App);
